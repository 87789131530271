import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Suspense,
  lazy,
} from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '@wix/da-hooks/pkg/useIsMounted';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import OnScrollToElement from '@wix/da-shared-react/pkg/OnScrollToElement';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import withSectionFetchSpinner from '../../../decorators/withSectionFetchSpinner';
import WideWidgetLayout from '../../Layout/WideWidgetLayout';
import StatsSideNavBar from './StatsSideNavBar';
import EmptyState from '../../EmptyState';
import { routes } from './consts';

// Lazy loaded widgets
const OverviewWidget = lazy(
  () => import(/* webpackChunkName: "stats" */ './OverviewWidget')
);
const MySubmissionsWidget = lazy(
  () => import(/* webpackChunkName: "stats" */ './MySubmissionsWidget')
);
const GeneralWidget = lazy(
  () => import(/* webpackChunkName: "stats" */ './GeneralWidget')
);
const TrafficWidget = lazy(
  () => import(/* webpackChunkName: "stats" */ './TrafficWidget')
);
const BenchmarkWidget = lazy(
  () => import(/* webpackChunkName: "stats" */ './BenchmarkWidget')
);

const AllTimeStatsWidget = lazy(
  () => import(/* webpackChunkName: "stats" */ './AllTimeStatsWidget')
);

const RecentVisitorsWidget = lazy(
  () => import(/* webpackChunkName: "stats" */ './RecentVisitors')
);

const BoostStatsWidget = lazy(
  () => import(/* webpackChunkName: "stats" */ './BoostStats')
);

import profilesCssVars from '../../../styles/variables.scss';
const scrollToChildOffsetTop = parseInt(profilesCssVars['navbar-height']);

import s from './StatsSection.scss';

export interface Props {
  hasCalculatedStats: boolean;
}

export const StatsSection: React.FC<Props> = ({ hasCalculatedStats }) => {
  const [activeHash, setActiveHash] = useState('overview');
  const isMounted = useIsMounted();
  const booster = useFlag('booster');
  const isMobile = useContext(MobileContext);
  const widgetRefs = useRef<HTMLDivElement[]>([]);
  const pushWidgetRef = ref => {
    widgetRefs.current.push(ref);
  };

  useEffect(() => {
    const initHash = get(document, 'location.hash').slice(1);
    if (initHash) {
      setActiveHash(initHash);
    }
  }, []);

  const updateActiveHash = (hash: string) => {
    setActiveHash(hash);
    typeof window !== 'undefined' &&
      window.history.replaceState({}, '', `#${hash}`);
  };

  const onScolledToElement = (element: HTMLElement) => {
    updateActiveHash(element.id);
  };

  const { t } = useTranslation();

  if (!isMounted) {
    return null;
  }

  if (!hasCalculatedStats) {
    return (
      <EmptyState
        title={t('pages.stats.empty.title')}
        subtitle={t('pages.stats.empty.subtitle')}
        className={s['empty']}
      />
    );
  }

  const commonWidgetProps = {
    ref: pushWidgetRef,
    widgetBoxClassName: s['widget'],
  };

  return (
    <WideWidgetLayout
      className={s['root']}
      widgetsRootClassName={s['widgets-root']}
      header={t('pages.stats.title')}
      subSubheader={t('pages.stats.subtitle')}
      navigation={
        !isMobile && (
          <StatsSideNavBar
            routes={
              booster
                ? [
                    ...routes,
                    {
                      hash: 'boosts',
                      transKey: 'pages.stats.widgets.boosts.title',
                    },
                  ]
                : routes
            }
            activeHash={activeHash}
            updateActiveHash={updateActiveHash}
          />
        )
      }
    >
      <Suspense fallback={LoadingIndicator as any}>
        <AllTimeStatsWidget id="alltime" {...commonWidgetProps} />
        <OverviewWidget
          id="overview"
          showMobileTimePeriodSwitcherOnlyOnStickyHeader={false}
          {...commonWidgetProps}
        />
        <RecentVisitorsWidget id="recent_visitors" {...commonWidgetProps} />
        <MySubmissionsWidget id="my_submissions" {...commonWidgetProps} />
        <TrafficWidget id="traffic" {...commonWidgetProps} />
        <GeneralWidget id="general" {...commonWidgetProps} />
        <BenchmarkWidget id="benchmark" {...commonWidgetProps} />
        {booster && <BoostStatsWidget id="boosts" {...commonWidgetProps} />}
        <ErrorBoundary debugComponent="StatsSection.ScrollToElement" hideOnFail>
          <OnScrollToElement
            elements={widgetRefs.current}
            offsetTop={scrollToChildOffsetTop}
            onScrollTo={onScolledToElement}
          />
        </ErrorBoundary>
      </Suspense>
      <div className={s['disclaimer']}>{t('pages.stats.disclaimer')}</div>
    </WideWidgetLayout>
  );
};
StatsSection.displayName = 'StatsSection';

export default withSectionFetchSpinner(StatsSection);
